/* * @Author: liuzhixiang * @Date: 2021-02-06 15:20:54 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-08-Tu 04:55:03 */
<template>
  <div
    class="question_details"
    :style="{ marginTop: isWxApplets ? '46px' : '' }"
  >
    <template v-if="isWxApplets">
      <van-nav-bar
        title="习题中心"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <div class="cont">
      <!-- <h3 class="">
        <el-select
          v-model="selectVal"
          @change="handleChange"
          placeholder="请选择课程"
          style="width: 100%"
        >
          <el-option
            v-for="item in courseList"
            :key="item.CourseId"
            :label="item.CourseName"
            :value="item.CourseId +','+item.IsActive"
          >
          </el-option>
        </el-select>
      </h3> -->
      <div class="top">
        <div class="sliding_box">
          <el-scrollbar>
            <ul>
              <li
                v-for="(item, index) in courseList"
                :key="index"
                @click="handleChange(item)"
                :class="item.CourseId == CourseId ? 'active' : ''"
              >
                {{ item.CourseName }}
              </li>
            </ul>
          </el-scrollbar>
        </div>
        <ul class="practice" v-if="selectVal.includes('英语')">
          <li
            v-for="(par, i) in practices"
            :key="i"
            :class="practicesIndex == par.id ? 'active' : ''"
            @click="practicesClick(par)"
            v-if="selectVal.includes('英语') && par.id > 5"
          >
            <div :class="practicesIndex == par.id ? 'bgc_F1C800' : ''">
              <i
                :class="
                  practicesIndex == par.id
                    ? par.icon + ' c_ffffff'
                    : par.icon + ' c_707070'
                "
                color="#E2EBFE"
                v-if="par.icon"
              ></i>
              <img
                v-else
                :src="
                  require('@/assets/images/' +
                    (practicesIndex == par.id
                      ? par.img + '_checked'
                      : par.img) +
                    '.png')
                "
                width="24"
                height="24"
                alt=""
              />
            </div>
            <p :class="practicesIndex == par.id ? 'active' : ''">
              {{ par.name }}
            </p>
          </li>
        </ul>
        <ul class="practice" v-if="!selectVal.includes('英语')">
          <li
            v-for="(par, i) in practices"
            :key="i"
            :class="practicesIndex == par.id ? 'active' : ''"
            @click="practicesClick(par)"
            v-if="!selectVal.includes('英语') && par.id <= 5"
          >
            <div :class="practicesIndex == par.id ? 'bgc_F1C800' : ''">
              <i
                :class="
                  practicesIndex == par.id
                    ? par.icon + ' c_ffffff'
                    : par.icon + ' c_707070'
                "
                color="#333333"
              ></i>
            </div>
            <p :class="practicesIndex == par.id ? '' : ''">
              {{ par.name }}
            </p>
          </li>
        </ul>
      </div>
      <div
        class="rollUp"
        v-if="
          practicesIndex == 1 ||
          practicesIndex == 3 ||
          practicesIndex == 4 ||
          practicesIndex == 5
        "
      >
        <div
          class="rollUp_top"
          v-if="
            practicesIndex != 5 && practicesIndex != 3 && practicesIndex != 4
          "
        >
          <el-scrollbar>
            <div class="li_box">
              <span
                v-for="(rl, i) in contacts"
                :key="i"
                :class="contactsIndex == i ? 'active' : ''"
                @click="contactsClick(rl, i)"
                >{{ rl }}
              </span>
            </div>
          </el-scrollbar>
        </div>
        <div class="rollUp_cont" v-if="practicesIndex == 1">
          <div v-if="list.length > 0">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="init"
            >
              <el-row
                type="flex"
                justify="center"
                v-for="(li, i) in list"
                :key="i"
                @click.native="parcticeClick(li)"
              >
                <el-col :span="16" style="padding-left: 10px"
                  >{{ li.Name }} ({{ li.QuestionCount }}题)</el-col
                >

                <el-col
                  :span="8"
                  class="text_align_right"
                  style="margin-top: 8px"
                >
                  <p class="c_1288F4">开始练习</p>
                </el-col>
              </el-row>
            </van-list>
          </div>
          <div v-else>
            <el-row>
              <el-col :span="24">
                <no-data></no-data>
              </el-col>
            </el-row>
          </div>
        </div>
        <div
          class="rollUp_cont"
          style="padding: 0"
          v-if="practicesIndex == 3 || practicesIndex >= 4"
        >
          <div class="rollUp_top" v-show="practicesIndex == 3">
            <el-scrollbar>
              <div class="li_box">
                <span
                  v-for="(qc, i) in questionCategory"
                  :key="i"
                  :class="qcIndex == i ? 'active' : ''"
                  @click="qcategoryClick(qc, i, 1)"
                  >{{ qc }}</span
                >
              </div>
            </el-scrollbar>
          </div>
          <div class="rollUp_top" v-show="practicesIndex == 4">
            <el-scrollbar>
              <div class="li_box">
                <span
                  v-for="(qc, i) in wordsCategory"
                  :key="i"
                  :class="wordsqcIndex == i ? 'active' : ''"
                  @click="qcategoryClick(qc, i, 2)"
                  v-if="selectVal.includes('英语') == false && i != 1 && i != 4"
                  >{{ qc }}</span
                >
              </div>
            </el-scrollbar>
          </div>
          <el-tree
            class="treepo"
            :indent="25"
            default-expand-all
            highlight-current
            :expand-on-click-node="false"
            :data="list"
            :props="defaultProps"
            @node-click="handleNodeClick"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span v-if="data.li_attr.questionCount > 0" class="tree_right">
                <!-- <el-progress
                  :percentage="
                    data.li_attr.AnswerCount / data.li_attr.questionCount * 100
                  "
                  :color="customColor"
                ></el-progress> -->
                <div class="proportion">
                  {{ data.li_attr.AnswerCount }}/{{
                    data.li_attr.questionCount
                  }}
                </div>
              </span>
            </span>
          </el-tree>
        </div>
        <div class="rollUp_cont" v-if="practicesIndex == 5">
          <div v-if="list.length > 0">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="init"
            >
              <el-row
                type="flex"
                justify="center"
                v-for="(li, i) in list"
                :key="i"
                @click.native="parcticeClick(li)"
              >
                <el-col :span="16" style="padding-left: 10px"
                  >{{ li.Name }} ({{ li.QuestionCount }}题)</el-col
                >

                <el-col
                  :span="8"
                  class="text_align_right"
                  style="margin-top: 8px"
                >
                  <p class="c_1288F4">开始练习</p>
                </el-col>
              </el-row>
            </van-list>
          </div>
          <div v-else>
            <el-row>
              <el-col :span="24">
                <no-data></no-data>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- <div>
          <el-pagination
            v-if="practicesIndex == 1 || practicesIndex == 5"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div> -->
      </div>

      <div class="rollUp" v-if="practicesIndex > 5">
        <div class="rollUp_top_first" v-if="practicesIndex === 7">
          <div class="day" @click="goToCalendar">
            <span>{{ studySituation.taskCompleteCount }}</span
            >天
          </div>
          <div class="set">
            <div @click="setStudy">
              设置学习目标
              <img
                src="@/assets/images/icon_set.png"
                width="16"
                alt=""
                srcset=""
              />
            </div>
            <div v-if="studySituation.todayIsStudy == 0" @click="handStudy">
              立即学习
              <img
                src="@/assets/images/icon_pen.png"
                width="16"
                alt=""
                srcset=""
              />
            </div>
            <div v-else @click="goToCalendar">
              已打卡
              <img
                src="@/assets/images/icon_canler.png"
                width="16"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
        <div class="rollUp_cont">
          <div v-if="list.length > 0 && practicesIndex == 6">
            <el-row
              type="flex"
              justify="center"
              v-for="(li, i) in list"
              :key="i"
              @click.native="parcticeClickAfter(li)"
            >
              <el-col :span="16" style="padding-left: 10px">
                {{ li.CategoryName }}
                （
                <span class="c_999999">
                  {{ li.DoQuestionCount }}/{{ li.AllQuestionCount }}
                </span>
                ）
              </el-col>
              <el-col
                :span="8"
                class="text_align_right"
                style="margin-top: 8px"
              >
                <img src="@/assets/images/icon_per.png" width="18" alt="" />
              </el-col>
            </el-row>
          </div>
          <div v-if="list.length > 0 && practicesIndex == 7">
            <el-row
              type="flex"
              justify="center"
              v-for="(li, i) in list"
              :key="i"
              @click.native="parcticeClickVocabulary(li)"
            >
              <el-col :span="16" style="padding-left: 10px">
                {{ li.CategroyName }}
                （
                <span class="c_999999">
                  {{ li.DoCount }}/{{ li.AllCount }}
                </span>
                ）
              </el-col>
              <el-col
                :span="8"
                class="text_align_right"
                style="margin-top: 8px"
              >
                <img src="@/assets/images/icon_per.png" width="18" alt="" />
              </el-col>
            </el-row>
            <div
              style="text-align: center; margin: 10px 0"
              v-if="refreshBtnShow"
            >
              <el-button
                type="primary"
                icon="el-icon-refresh"
                @click="resetEnglishPractice()"
                >重新开始</el-button
              >
            </div>
          </div>
          <div v-if="list.length > 0 && practicesIndex == 8">
            <el-row
              type="flex"
              justify="center"
              v-for="(li, i) in list"
              :key="i"
              @click.native="parcticeClickPattern(li)"
            >
              <el-col :span="16" style="padding-left: 10px">
                {{ li.CategroyName }}
                （
                <span class="c_999999">
                  {{ li.DoCount }}/{{ li.AllCount }}
                </span>
                ）
              </el-col>
              <el-col
                :span="8"
                class="text_align_right"
                style="margin-top: 8px"
              >
                <img src="@/assets/images/icon_per.png" width="18" alt="" />
              </el-col>
            </el-row>
          </div>
          <!-- <div v-else>
            <el-row>
              <el-col :span="24">
                <no-data></no-data>
              </el-col>
            </el-row>
          </div> -->
        </div>
      </div>
    </div>
    <camera
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @close="getClose()"
      @studentface="postStudentCompareFace"
    ></camera>
  </div>
</template>

<script>
import camera from "@/components/canvasCamera.vue";
import { questionBankQueryQuestionCourse } from "@/api/personal";
import {
  questionBankQueryPaperList,
  questionBankQueryChapters,
  queryStudySituation,
  queryEnglishCategory,
  setWordsTask,
  queryPracticeInfo,
  querySentenceCategory,
  resetEnglishPractice,
} from "@/api/questionBank";
import { studentCompareFace } from "@/api/api";
import noData from "@/components/noData";
import { Dialog } from "vant";
export default {
  data() {
    return {
      refreshBtnShow: false,
      question: {},
      practices: [
        { id: 1, name: "套卷练习", icon: "el-icon-tickets" },
        // { id: 2, name: "随机练习", icon: "el-icon-document" },
        { id: 3, name: "章节练习", icon: "el-icon-notebook-2" },
        { id: 4, name: "名词刷题", icon: "el-icon-document-checked" },
        // { id: 5, name: "题型练习", icon: "el-icon-reading" },
        { id: 6, name: "课后练习", icon: "el-icon-tickets" },
        { id: 7, name: "词汇", icon: "", img: "icon_en" },
        { id: 8, name: "句式分析", icon: "", img: "icon_en1" },
      ],
      practicesIndex: 1,
      list: [],
      contacts: [],
      contactsIndex: 0,
      rollUps: ["全部", "模拟考试", "历年真题", "专项练习"],
      questionCategory: [
        "全部",
        "单选题",
        "多选题",
        "判断题",
        "填空题",
        "简答题",
        "论述题",
      ],
      wordsCategory: ["全部", "英语单词", "专业名词", "作品解析", "句式分析"],
      bankqcId: -1,
      qcIndex: 0,
      wordsqcId: -1,
      wordsqcIndex: 0,
      chapters: ["章节"],
      paperType: "",
      page: 1, //页码
      pageSize: 10, //每页条数
      total: 0,
      defaultProps: {
        label: "text",
        children: "children",
      },
      customColor: "#1288f4",
      dialogVisible: false,
      jump: {},
      user: {},
      selectVal: "",
      myCourseId: 0,
      courseList: [],
      route: {},
      courseActive: true,
      loading: false,
      finished: false,
      studySituation: {},
      lishCategory: [],
      list: [],
      isWxApplets: true,
    };
  },
  components: {
    camera,
    noData,
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "习题中心";
      this.isWxApplets = false;
    }
    // this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.contacts = this.rollUps;
    this.route = this.$route.query;
    this.getQuestionBankQueryQuestionCourse();
    var that = this;
    window["callByAndroidParam"] = function (jsonStr) {
      if (jsonStr != "") {
        that.$router.push(jsonStr);
      } else {
        this.$message.error("人脸识别失败，请重试");
      }
    };
  },
  mounted() {
    window.callByIosParam = this.callByIosParam;
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    callByIosParam(jsonStr) {
      // this.$message.error("接收到的参数："+jsonStr);
      if (jsonStr != "") {
        this.$router.push(JSON.parse(jsonStr));
      } else {
        this.$message.error("人脸识别失败，请重试");
      }
    },
    // 打卡日历
    goToCalendar() {
      let url = "/H5/questionCenter/punchCalendar";
      let obj = {
        UserID: this.route.UserID,
        courseId: this.myCourseId,
      };
      this.$router.push({ path: url, query: obj });
    },
    // 设置学习目标
    setStudy() {
      let url = "/H5/questionCenter/setGoals";
      let obj = {
        UserID: this.route.UserID,
        courseId: this.myCourseId,
      };
      this.$router.push({ path: url, query: obj });
    },
    resetEnglishPractice() {
      let parm = {
        StudentId: this.route.UserID,
        CourseId: this.myCourseId,
      };
      Dialog.confirm({
        title: "提示",
        message: "若重新学习所有的单词，刷词记录将被清空，是否重新学习？",
        messageAlign: "left",
      })
        .then(() => {
          // const res = resetEnglishPractice(parm);
          // Dialog.alert({
          //   message: res.msg,
          // }).then(() => {
          //   // on close
          // });
          this.postResetEnglishPractice(parm);
        })
        .catch(() => {
          // on cancel
        });
    },
    async postResetEnglishPractice(parm) {
      const res = await resetEnglishPractice(parm);
      if (res) {
        this.getQueryEnglishCategory();
        Dialog.alert({
          message: res.msg,
        }).then(() => {
          // on close
        });
      }
    },
    // 立即学习
    handStudy() {
      if (!this.studySituation.taskSettingEntity) {
        this.$message.error("请设置学习目标");
        return false;
      }
      this.postSetWordsTask();
    },
    // 每日任务生成 —— 进入学习时调用
    async postSetWordsTask() {
      let parm = {
        studentId: this.route.UserID,
        // studentName: this.route.UserID,
        courseId: this.myCourseId,
        courseName: this.selectVal,
        taskDate: this.common.dataYMD(new Date()),
        newWordsCount: this.studySituation.taskSettingEntity.NewWordsCount,
        checkWordsCount: this.studySituation.taskSettingEntity.CheckWordsCount,
      };
      const res = await setWordsTask(parm);
      if (res.success === true) {
        let obj = {
          UserID: this.route.UserID,
          courseId: this.myCourseId,
          title: "立即学习",
          type: 1,
          taskId: res.response.Id,
          newWordsCount: this.studySituation.taskSettingEntity.NewWordsCount,
          checkWordsCount:
            this.studySituation.taskSettingEntity.CheckWordsCount,
        };
        this.goVocabulary(obj);
      } else {
        this.$message.error(res.msg);
      }
    },
    goVocabulary(obj) {
      let url = "/H5/questionCenter/vocabulary";
      this.$router.push({ path: url, query: obj });
    },
    // 查询学习信息
    async getQueryStudySituation() {
      let parm =
        "?courseId=" + this.myCourseId + "&studentId=" + this.route.UserID;
      const res = await queryStudySituation(parm);
      if (res.success == true) {
        this.studySituation = res.response;
      }
    },
    // 获取单词分类
    async getQueryEnglishCategory() {
      let parm =
        "?courseId=" + this.myCourseId + "&studentId=" + this.route.UserID;
      const res = await queryEnglishCategory(parm);
      let doAllCount = 0;
      if (res.success == true) {
        this.list = res.response;
        res.response.forEach((element) => {
          // console.log(element)
          if (element.AllCount == element.DoCount) {
            doAllCount++;
          }
        });
        // this.refreshBtnShow = true;
        this.refreshBtnShow = doAllCount == res.response.length;
        // console.log(res.response);
      }
    },
    // 词汇跳转
    parcticeClickVocabulary(obj) {
      if (obj.DoCount >= obj.AllCount) {
        this.$message.error("暂无更多答题");
        return;
      }
      let ob = {
        UserID: this.route.UserID,
        courseId: this.myCourseId,
        title: obj.CategroyName,
        CategroryId: obj.CategroryId,
        type: 2,
      };
      this.goVocabulary(ob);
    },
    // 句式分析跳转
    parcticeClickPattern(obj) {
      if (obj.DoCount >= obj.AllCount) {
        this.$message.error("暂无更多答题");
        return;
      }
      let path = "/H5/questionCenter/translate";
      let ob = {
        UserID: this.route.UserID,
        courseId: this.myCourseId,
        CategroryId: obj.CategroryId,
        type: 0,
        exercises: 0,
      };
      this.$router.push({ path: path, query: ob });
    },
    // 词汇与语法跳转
    parcticeClickAfter(obj) {
      if (obj.DoQuestionCount >= obj.AllQuestionCount) {
        this.$message.error("暂无更多答题");
        return;
      }
      let path = "",
        ob = {};
      ob = {
        UserID: this.route.UserID,
        courseId: this.myCourseId,
      };
      if (obj.QuestionCategory == -1) {
        path = "/H5/questionCenter/lexicalGrammar";
        ob.exercises = 0;
      } else if (obj.QuestionCategory == 1) {
        path = "/H5/questionCenter/translate";
        ob.type = 1;
        ob.exercises = 0;
      } else if (obj.QuestionCategory == 2) {
        path = "/H5/questionCenter/writing";
        ob.type = 2;
        ob.exercises = 0;
      }
      this.$router.push({ path: path, query: ob });
    },
    // 句式分析分类
    async getQuerySentenceCategory() {
      let parm =
        "?courseId=" + this.myCourseId + "&studentId=" + this.route.UserID;
      const res = await querySentenceCategory(parm);
      if (res.success == true) {
        this.list = res.response;
      }
    },
    // 课后练习
    async getQueryPracticeInfo() {
      let parm =
        "?courseId=" + this.myCourseId + "&studentId=" + this.route.UserID;
      const res = await queryPracticeInfo(parm);
      if (res.success == true) {
        this.list = res.response;
      }
    },
    async appCompareFace(parm) {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      //  console.log(window.android);
      // console.log(isiOS);
      // console.log(parm);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(parm);
      } else if (isiOS) {
        window.webkit.messageHandlers.callIos.postMessage(parm);
      }
    },
    getClose() {
      this.dialogVisible = false;
    },
    // 人脸识别
    async postStudentCompareFace(file) {
      // const formData = new FormData();
      // formData.append("file", file);
      // const res = await studentCompareFace(formData);
      // if (res.success == true) {
      //   this.$message.success(res.msg);
      //   this.$router.push({ path: this.jump.url, query: this.jump.ob });
      // } else {
      //   this.$message.error(res.msg);
      //   this.dialogVisible = false;
      // }
      this.$router.push({ path: this.jump.url, query: this.jump.ob });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
    },
    handleNodeClick(obj) {
      if (this.courseActive == "false") {
        this.$message.error("当前课程不在有效期内，不能练习");
        return false;
      }
      let ob = {};
      if (obj.li_attr.questionCount > 0) {
        if (this.practicesIndex == 3) {
          ob = {
            Id: obj.id,
            CourseId: this.myCourseId,
            Name: obj.text,
            ChapterId: obj.id,
            UserID: this.route.UserID, //this.user.Id,
            questionCategory: this.bankqcId,
          };
          this.jump.url = "/H5/chapterExercises";
        } else if (this.practicesIndex >= 4) {
          ob = {
            Id: obj.id,
            CourseId: this.myCourseId,
            ChapterId: obj.id,
            Name: obj.text,
            UserID: this.route.UserID, //this.user.Id,
            questionCategory: this.wordsqcId,
          };
          this.jump.url = "/H5/topicPaper";
        }
        this.jump.ob = ob;
        this.$router.push({ path: this.jump.url, query: this.jump.ob });
        //this.dialogVisible = true;
        // this.appCompareFace(
        //   JSON.stringify({
        //     action: "compareFace",
        //     path: this.jump.url,
        //     query: this.jump.ob,
        //   })
        // );
      } else {
        this.$message.error("该节点没有任何题目");
      }
    },
    parcticeClick(obj) {
      // let ob = {
      //   Id: obj.Id,
      // };
      // this.$router.push({
      //   path: "/onlineQuestionBank/practiceDetails",
      //   query: ob,
      // });
      if (this.courseActive == "false") {
        this.$message.error("当前课程不在有效期内，不能练习");
        return false;
      }
      let ob = {
        Id: obj.Id,
        Name: obj.Name,
        UserID: this.route.UserID, //this.user.Id,
      };
      this.jump.url = "/H5/testPaper";
      this.jump.ob = ob;
      this.$router.push({ path: this.jump.url, query: this.jump.ob });
      //免费试卷不需要人脸识别
      // if (obj.Category == 2) {
      //   this.$router.push({ path: this.jump.url, query: this.jump.ob });
      // } else {
      //   this.appCompareFace(
      //     JSON.stringify({
      //       action: "compareFace",
      //       path: this.jump.url,
      //       query: this.jump.ob,
      //     })
      //   );
      // }
    },
    //考题类型
    contactsClick(obj, i) {
      this.contactsIndex = i;
      if (this.contactsIndex == 0) {
        this.paperType = "";
      } else if (this.contactsIndex == 1) {
        this.paperType = 0;
      } else if (this.contactsIndex == 2) {
        this.paperType = 1;
      } else if (this.contactsIndex == 3) {
        this.paperType = 2;
      }
      this.list = [];
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.init();
    },
    qcategoryClick(obj = {}, i, category) {
      if (category == 1) {
        this.bankqcId = i - 1;
        this.qcIndex = i;
      }
      if (category == 2) {
        this.wordsqcId = i - 1;
        this.wordsqcIndex = i;
      }
      this.getQuestionBankQueryChapters();
    },
    //练习模式
    practicesClick(obj) {
      // if(this.user == null){
      //   this.$message.error("请先登陆");
      //   return false;
      // }

      this.page = 1;
      this.practicesIndex = obj.id;
      this.contactsIndex = 0;
      this.list = [];
      let ob = {
        Id: this.myCourseId,
        Name: this.selectVal,
        UserID: this.route.UserID, //this.user.Id,
      };
      // 1套卷练习2随机练习3章节练习4名词刷题5题型练习
      if (obj.id == 1) {
        this.contacts = this.rollUps;
        this.paperType = "";
        this.list = [];
        this.page = 1;
        this.loading = false;
        this.finished = false;
        this.init();
      } else if (obj.id == 2) {
        this.$router.push({
          path: "/onlineQuestionBank/randomAnswer",
          query: ob,
        });
      } else if (obj.id == 3 || obj.id == 4) {
        this.contacts = this.chapters;
        this.getQuestionBankQueryChapters();
      } else if (obj.id == 4) {
        // this.jump.url = "/onlineQuestionBank/recitationMode";
        // this.jump.ob = ob;
        // // this.$router.push({ path: this.jump.url, query: this.jump.ob });
        // this.dialogVisible = true;
      } else if (obj.id == 5) {
        this.paperType = 2;
        this.list = [];
        this.page = 1;
        this.loading = false;
        this.finished = false;
        this.init();
      } else if (obj.id == 6) {
        this.getQueryPracticeInfo();
      } else if (obj.id == 7) {
        this.getQueryStudySituation();
        this.getQueryEnglishCategory();
      } else if (obj.id == 8) {
        this.getQuerySentenceCategory();
      }
    },
    async getQuestionBankQueryChapters() {
      let obj = "";
      if (this.practicesIndex == 3) {
        obj =
          "?courseId=" +
          this.myCourseId +
          "&studentId=" +
          this.route.UserID +
          "&questionCategory=" +
          this.bankqcId;
      } else if (this.practicesIndex >= 4) {
        obj =
          "?courseId=" +
          this.myCourseId +
          "&isWords=true&studentId=" +
          this.route.UserID +
          "&questionCategory=" +
          this.wordsqcId;
      }
      if (this.myCourseId > 0) {
        const res = await questionBankQueryChapters(obj);
        if (res.success === true) {
          this.list = res.response;
        } else {
          this.$message.error(res.msg);
        }
      } else {
        this.list = [];
      }
    },
    async init() {
      this.question = this.$route.query;
      let parm = {
        studentId: this.route.UserID, //this.user.Id,
        courseId: this.myCourseId,
        pageIndex: this.page,
        pageSize: this.pageSize,
        PaperType: this.paperType, //试卷类型，不传时全部，0模拟试卷，1真题
      };
      const res = await questionBankQueryPaperList(parm);
      if (res.success === true) {
        // this.total = res.response.dataCount;
        this.list = this.list.concat(res.response.data);
        this.page++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    async getQuestionBankQueryQuestionCourse() {
      let parm = {
        studentId: this.route.UserID,
      };
      const res = await questionBankQueryQuestionCourse(parm);
      if (res.success === true) {
        this.courseList = res.response;
        this.CourseId = this.courseList[0].CourseId;
        this.selectVal = this.courseList[0].CourseName;
        this.myCourseId = this.courseList[0].CourseId;
        this.courseActive = String(this.courseList[0].IsActive);
        // this.$router.push({ path: this.$route.path, query: {CourseId:this.CourseId}});
        this.list = [];
        this.page = 1;
        this.loading = false;
        this.finished = false;
        this.init();
      } else {
        this.$message.error(res.msg);
      }
    },
    handleChange(obj) {
      // console.log(obj);
      // console.log(this.practicesIndex);
      // this.CourseId = value.split(',')[0];
      // this.myCourseId = value.split(',')[0];
      // this.courseActive = value.split(',')[1];
      this.CourseId = obj.CourseId;
      this.myCourseId = obj.CourseId;
      this.selectVal = obj.CourseName;
      this.courseActive = obj.IsActive;
      if (obj.CourseName.includes("英语")) {
        this.wordsqcId = 0;
        this.practicesIndex = 6;
      } else {
        if (this.practicesIndex > 4) {
          this.practicesIndex = 4;
          this.contacts = this.rollUps;
        }
      }
      // this.$router.push({ path: this.$route.path, query: {CourseId:this.CourseId}});
      if (this.practicesIndex == 3 || this.practicesIndex == 4) {
        this.qcIndex = 0;
        this.wordsqcIndex = 0;
        this.getQuestionBankQueryChapters();
      } else if (this.practicesIndex == 6) {
        this.getQueryPracticeInfo();
      } else {
        this.list = [];
        this.page = 1;
        this.loading = false;
        this.finished = false;
        this.init();
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-tree-node__children {
  margin-left: -12px;
}
.question_details {
  font-size: 14px;
  .cont {
    .top {
      width: 100%;
      min-height: 153px;
      // background: url("../../../assets/images/bg_h_pic.png");
      // background-repeat: no-repeat;
      // background-size: 100%;
      background: #fff;
      .sliding_box {
        padding: 20px 10px;
        ul {
          display: flex;
          li {
            margin-right: 10px;
            display: inline-block;
            min-width: 115px;
            height: 24px;
            line-height: 24px;
            padding: 0 10px;
            border-radius: 20px;
            flex-shrink: 0;
            color: #afafaf;
          }
          .active {
            background: #ffffff;
            color: #333;
          }
        }
      }
    }
    .title {
      line-height: 60px;
      background-color: #fff;
      padding: 0 30px;
      margin: 10px 0;
      font-size: 16px;
    }
    .practice {
      overflow: hidden;
      background-color: #fff;
      margin: 0 10px;
      border-radius: 10px;
      li {
        text-align: center;
        width: 33%;
        float: left;
        padding: 25px 0 20px;
        div {
          width: 56px;
          height: 56px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          // background: #f5f5f5;
          border-radius: 50%;
        }
        i {
          font-size: 24px;
        }
        p {
          margin-top: 10px;
        }
        .c_707070 {
          color: #707070;
        }
        .c_3A78F9 {
          color: #3a78f9;
        }
        .bgc_E2EBFE {
          background-color: #e2ebfe;
        }
        .active {
          color: #1288f4;
        }
      }
    }
    .rollUp {
      .rollUp_top {
        padding: 0 10px;
        margin: 10px 0;
        background-color: #fff;
        color: #afafaf;
        .li_box {
          display: flex;
        }
        span {
          display: inline-block;
          min-width: 70px;
          text-align: center;
          margin-right: 10px;
          line-height: 60px;
          cursor: pointer;
        }
        .active {
          color: #333;
          position: relative;
          &::before {
            content: "";
            width: 100%;
            height: 3px;
            background-color: #f1c800;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
      .rollUp_top_first {
        background-color: #fff;
        margin: 10px;
        padding: 10px 15px;
        border-radius: 10px;
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .day {
          span {
            font-size: 34px;
            font-weight: bold;
            font-family: DIN;
            line-height: 1;
          }
        }
        .set {
          display: flex;
          div {
            &:first-child {
              margin-right: 15px;
              padding-right: 15px;
              border-right: 1px solid #c8c7c7;
            }
          }
          img {
            vertical-align: text-bottom;
            margin-left: 5px;
          }
          span {
            color: #c8c7c7;
            margin: 0 15px;
          }
        }
      }
      .rollUp_cont {
        // padding: 0 40px;
        padding: 20px 0;
        margin: 10px 0;
        background-color: #fff;
        .el-tree {
          padding: 0 10px;
          .custom-tree-node {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            padding-right: 8px;
            .tree_right {
              display: flex;
              /deep/.el-progress {
                width: 120px;
                .el-progress-bar {
                  padding-right: 5px !important;
                }
                .el-progress__text {
                  display: none;
                }
              }
              .proportion {
                min-width: 45px;
                text-align: right;
              }
            }
          }
        }
        .el-row {
          line-height: 40px;
          border-bottom: 1px solid #efefef;
          .el-col {
            cursor: pointer;
            &:first-child {
              padding-left: 40px;
            }
            &:last-child {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
  // .el-tree-node>.el-tree-node__children {
  //    margin-left: -15px !important;
  // }
}
/deep/.el-scrollbar {
  .el-scrollbar__bar {
    display: none;
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
